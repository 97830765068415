.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    pointer-events: none;
}

.icon {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
}