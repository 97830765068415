/* .container {
    height: 100%;
    width: 100%;
    background-color: bisque;
    border-color: white;
    border-style: solid;
    border-width: 1px;
} */

.container {
    position: relative;
    margin: 1px;
    background-color: bisque;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }
  
.container::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.selectable {
    cursor: pointer
}

.selectable:hover {
    border-color: darkslategrey;
}

.container .content {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
  
    display: flex;         
    justify-content: center;
    align-items: center;
}

.svgDiv {
    width: 30%;
    display: flex;
}
