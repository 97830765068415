.container {
    border-style: solid;
    border-color: brown;
    border-width: 10px;
    display: flex;
    flex-wrap: wrap;
}

.header{
    display: flex;
    justify-content: space-evenly;
}

.column {
    padding: 0px;
}