.container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.topContainer {
    display: flex;
    justify-content: space-evenly;
}

.bedEditor {
    margin-top: 20px;
}